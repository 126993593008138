import {Component, OnInit} from '@angular/core';
import {LogoEnum} from "../../../../constants/Logo.enum";

declare const $: any;

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    parent?: number;
}

export const ROUTES: RouteInfo[] = [
    {path: '/dashboard', title: 'Dashboard', icon: 'dashboard', class: ''},
    {path: '/liste-des-clients', title: 'Liste des clients', icon: 'supervisor_account', class: ''},
    {path: '/liste-des-dispositifs', title: 'Liste des dispositifs', icon: 'subscriptions', class: ''},
    {path: '/types-de-dispositifs', title: 'Type de dispositifs', icon: 'list_alt', class: ''},
    {path: '/liste-des-widgets', title: 'Liste des widgets', icon: 'content_paste', class: ''},
    {path: '/types-de-widgets', title: 'Types de Widgets', icon: 'content_paste', class: ''},
];

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
    menuItems: any[];
    logo: string = '';

    constructor() {
    }

    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        let userJson = localStorage.getItem('admin_user');
        this.logo = LogoEnum.dacqsysLogo;
        if (userJson) {
            let user = JSON.parse(userJson);
            if (user !== null) {
                this.logo = user.logo;
            }
        }
    }

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };
}
