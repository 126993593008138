import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {RouterModule, Routes} from '@angular/router';
import {AppRoutingModule} from './app.routing';
import {ComponentsModule} from './components/components.module';
import {AppComponent} from './app.component';
import {AdminLayoutComponent} from './layouts/admin-layout/admin-layout.component';
import {JwtHelperService, JwtModule} from "@auth0/angular-jwt";
import {AuthService} from "../services/auth.service";
import {AuthInterceptor} from "../interceptors/auth.interceptor";
import {AuthGuard} from "../guards/auth.guard";
import {AuthenticationComponent} from "./authentication/authentication.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {CommonModule} from "@angular/common";
import {ImageCropperModule} from "ngx-image-cropper";
import {MatTableModule} from "@angular/material/table";
import {GoogleChartsModule} from "angular-google-charts";
import {jqxLinearGaugeModule} from "jqwidgets-ng/jqxlineargauge";
import {jqxGaugeModule} from "jqwidgets-ng/jqxgauge";
import {ForgottenComponent} from "./authentication/forgotten.component";
import {MatCheckboxModule} from "@angular/material/checkbox";

let routes: Routes = [
    // ... vos routes ici ...
];

@NgModule({
    imports: [
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        ComponentsModule,
        RouterModule,
        AppRoutingModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: () => {
                    return localStorage.getItem('admin_access_token');
                }
            }
        }),
        MatButtonModule,
        MatInputModule,
        MatIconModule,
        MatFormFieldModule,
        CommonModule,
        ImageCropperModule,
        MatTableModule,
        GoogleChartsModule,
        jqxGaugeModule,
        jqxLinearGaugeModule,
        MatCheckboxModule
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        AuthenticationComponent,
        ForgottenComponent
    ],
    providers: [JwtHelperService, AuthService, AuthGuard, {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
    }],
    bootstrap: [AppComponent]
})
export class AppModule {
}
