import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {LoaderService} from "../services/loader.service";
import {finalize} from "rxjs/operators";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private totalRequests = 0;

    constructor(private authService: AuthService, private loadingService: LoaderService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.totalRequests++;
        this.loadingService.setLoading(true);
        const token = localStorage.getItem('admin_access_token');
        if (token) {
            const tokenExpired = this.authService.isTokenExpired(token);
            if (tokenExpired) {
                this.authService.redirectAfterTokenExpired(tokenExpired);
                return new Observable<HttpEvent<any>>();
            } else {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token}`
                    }
                });
            }
        }
        return next.handle(request).pipe(
            finalize(() => {
                this.totalRequests--;
                if (this.totalRequests == 0) {
                    this.loadingService.setLoading(false);
                }
            })
        );
    }
}
