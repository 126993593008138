<app-spinner></app-spinner>
<div class="container" style="max-width:100%">
    <form (ngSubmit)="forgotten()" [formGroup]="loginForm" style="width: 500px">
        <h2>Mot de passe oublié ?</h2>
        <mat-form-field appearance="fill">
            <mat-label>Login ou Email</mat-label>
            <input formControlName="emailClient" matInput required type="text">
            <mat-error *ngIf="loginForm?.get('emailClient')?.hasError('required')">
                Ce champ est requis.
            </mat-error>
        </mat-form-field>
        <div class="error" *ngIf="forgottenError">
            <div>{{forgottenErrorData}}</div>
        </div>

        <div class="success" *ngIf="forgottenSuccess">
            <div>{{forgottenErrorData}}</div>
        </div>
        <div class="col-12">
        <button type="submit" mat-raised-button color="accent" [disabled]="!loginForm.valid" class="col-xs-12 col-md-6 pull-right">Réinitialiser le mot de
            passe
        </button>
        <button [routerLink]="['/login']" mat-raised-button color="primary" class="col-xs-12 col-md-6 pull-left">Se connecter</button>
        </div>
    </form>
</div>
