<app-spinner></app-spinner>
<div class="container" style="max-width:100%">
    <form (ngSubmit)="login()" [formGroup]="loginForm" style="width: 400px">
        <h2>Authentification</h2>
        <mat-form-field appearance="fill">
            <mat-label>Login ou Email</mat-label>
            <input formControlName="emailClient" matInput required type="text">
            <mat-error *ngIf="loginForm?.get('emailClient')?.hasError('required')">
                Ce champ est requis.
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Mot de passe</mat-label>
            <input matInput [type]="showPassword ? 'text' : 'password'" formControlName="password" required>
      <mat-icon matSuffix (click)="showPassword = !showPassword">{{ showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
      <mat-error *ngIf="loginForm?.get('password')?.hasError('required')">
      Ce champ est requis.
      </mat-error>
    </mat-form-field>
    <div class="error">
      <div *ngIf="loginError">{{loginErrorData}}</div>
    </div>
        <a [routerLink]="['/mot-de-pass-oublie']">Mot de passe oublié?</a>
	  <button type="submit" mat-raised-button color="primary" [disabled]="!loginForm.valid">Se connecter</button>
  </form>
</div>
