<footer class="footer ">
    <div class="container-fluid">
        <nav class="pull-left">
            <ul>
                <li>
                    <a href="https://www.gce.com">
                        GCE
                    </a>
                </li>
                <li>
                    <a href="https://gce.com/about-us">
                        About Us
                    </a>
                </li>
            </ul>
        </nav>
        <div class="copyright pull-right">
            &copy;
            {{test | date: 'yyyy'}},
            <a href="https://www.gce.com" target="_blank">DACQSYS by GCE</a>.
        </div>
    </div>
</footer>
