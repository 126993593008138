<nav class="navbar navbar-expand-lg navbar-transparent  navbar-absolute">
    <div class="container-fluid">
        <div class="navbar-minimize">
            <button (click)="sidebarToggle()" class="btn btn-just-icon btn-white btn-fab btn-round" mat-raised-button>
                <mat-icon class="material-icons text_align-center visible-on-sidebar-regular">more_vert</mat-icon>
                <mat-icon class="material-icons design_bullet-list-67 visible-on-sidebar-mini">view_list</mat-icon>
            </button>
        </div>
        <div class="navbar-wrapper">
            <a class="navbar-brand" href="javascript:void(0)">{{getTitle()}}</a>
        </div>
        <button (click)="sidebarToggle()" class="navbar-toggler" mat-raised-button type="button">
            <mat-icon class="material-icons text_align-center visible-on-sidebar-regular">more_vert</mat-icon>
            <mat-icon class="material-icons design_bullet-list-67 visible-on-sidebar-mini">view_list</mat-icon>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="navigation">
            <form class="navbar-form">
                <div class="input-group no-border">
                    <input class="form-control" placeholder="Search..." type="text" value="">
                    <button class="btn btn-white btn-round btn-just-icon" mat-raised-button type="submit">
                        <i class="material-icons">search</i>
                        <div class="ripple-container"></div>
                    </button>
                </div>
            </form>
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a class="nav-link" href="/">
                        <i class="material-icons">dashboard</i>
                        <p>
                            <span class="d-lg-none d-md-block">Tableau de bord</span>
                        </p>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/mon-compte">
                        <i class="material-icons">person</i>
                        <p>
                            <span class="d-lg-none d-md-block">Account</span>
                        </p>
                    </a>
                </li>
                <li class="nav-item dropdown">
                    <button (click)="logout()" class="logout-button" mat-button>
                        Déconnexion
                    </button>
                </li>
            </ul>
        </div>
    </div>
</nav>

<!--

<nav class="navbar navbar-transparent navbar-absolute">
    <div class="container-fluid">
        <div class="navbar-header">
            <button mat-raised-button type="button" class="navbar-toggle" data-toggle="collapse" (click)="sidebarToggle()">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <a class="navbar-brand" href="javascript:void(0)">{{getTitle()}}</a>
        </div>
        <div class="collapse navbar-collapse">
            <ul class="nav navbar-nav navbar-right">
                <li>
                    <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown">
                        <i class="material-icons">dashboard</i>
                        <p class="hidden-lg hidden-md">Dashboard</p>
                    </a>
                </li>
                <li class="dropdown">
                    <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown">
                        <i class="material-icons">notifications</i>
                        <span class="notification">5</span>
                        <p class="hidden-lg hidden-md">Notifications</p>
                    </a>
                    <ul class="dropdown-menu">
                        <li><a href="javascript:void(0)">Mike John responded to your email</a></li>
                        <li><a href="javascript:void(0)">You have 5 new tasks</a></li>
                        <li><a href="javascript:void(0)">You're now friend with Andrew</a></li>
                        <li><a href="javascript:void(0)">Another Notification</a></li>
                        <li><a href="javascript:void(0)">Another One</a></li>
                    </ul>
                </li>
                <li>
                    <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown">
                       <i class="material-icons">person</i>
                       <p class="hidden-lg hidden-md">Profile</p>
                    </a>
                </li>
            </ul>

            <form class="navbar-form navbar-right" role="search">
                <div class="form-group form-black is-empty">
                    <input type="text" class="form-control" placeholder="Search">
                    <span class="material-input"></span>
                </div>
                <button mat-raised-button type="submit" class="btn btn-white btn-round btn-just-icon">
                    <i class="material-icons">search</i><div class="ripple-container"></div>
                </button>
            </form>
        </div>
    </div>
</nav> -->
