import {NgModule} from '@angular/core';
import {CommonModule,} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';

import {AdminLayoutComponent} from './layouts/admin-layout/admin-layout.component';
import {AuthenticationComponent} from "./authentication/authentication.component";
import {AuthGuard} from "../guards/auth.guard";
import {ForgottenComponent} from "./authentication/forgotten.component";

const routes: Routes = [
    {
        path: '',
        component: AdminLayoutComponent,
        children: [{
            path: '',
            loadChildren: () => import('./layouts/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule)
        }], canActivate: [AuthGuard]
    },
    {path: 'login', component: AuthenticationComponent, pathMatch: 'full',},
    {path: 'mot-de-pass-oublie', component: ForgottenComponent, pathMatch: 'full',},
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes, {
            useHash: true
        })
    ],
    exports: [],
})
export class AppRoutingModule {
}
