import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {AuthTypes} from "../constants/AuthTypes.enum";

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) {
    }

    /**
     * Vérifie si déjà authentifié
     */
    canActivate(): boolean {
        // tester si le token est déjà dans le localstorage
        const tokenUserInfo = this.authService.getUserInfo();
        const tokenExpired = this.authService.isTokenExpired(localStorage.getItem('admin_access_token'));
        if (!tokenExpired   && tokenUserInfo.role === AuthTypes.SuperAdministrator) {
            //this.router.navigate(['dashboard']);
            return true;
        }
        this.router.navigate(['login'])
        return false;
    }

}
