import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from "../../services/auth.service";
import {AuthTypes} from "../../constants/AuthTypes.enum";

@Component({
    selector: 'app-forgotten',
    templateUrl: './forgotten.component.html',
    styleUrls: ['./forgotten.component.scss']
})

export class ForgottenComponent implements OnInit {
    loginForm: UntypedFormGroup;
    username!: string;
    password!: string;
    showPassword: boolean = false;
    forgottenError: boolean = false;
    forgottenSuccess: boolean = false;
    forgottenErrorData: string = 'Email ou mot de passe invalide, veuillez réessayer.';

    constructor(private formBuilder: UntypedFormBuilder, private authService: AuthService, private router: Router) {
        // sinon on affiche le formulaire
        this.loginForm = this.formBuilder.group({
            emailClient: ['', [Validators.required]],
        });
    }

    ngOnInit() {
    }

    forgotten() {
        if (this.loginForm.valid) {
            this.username = this.loginForm.get('emailClient')?.value;
            this.authService.forgotten(this.username).subscribe({
                next: (forgonntenResponse) => {
                    this.forgottenErrorData = forgonntenResponse.message;
                    if(forgonntenResponse.status === 'OK'){
                        this.forgottenSuccess = true;
                    }else{
                        this.forgottenError = true;
                    }
                },
                error: (error) => {
                    this.forgottenErrorData = error;
                    this.forgottenError = true;
                },
            });
        }
    }
}
