<div class="logo">
    <a class="simple-text" href="/">
        <div class="logo-img">
            <img src="{{ logo }}"/>
        </div>
    </a>
</div>
<div class="sidebar-wrapper">
    <div *ngIf="isMobileMenu()">
        <form class="navbar-form">
      <span class="bmd-form-group">
        <div class="input-group no-border">
          <input class="form-control" placeholder="Search..." type="text" value="">
          <button class="btn btn-white btn-round btn-just-icon" mat-raised-button type="submit">
            <i class="material-icons">search</i>
            <div class="ripple-container"></div>
          </button>
        </div>
      </span>
        </form>
        <ul class="nav navbar-nav nav-mobile-menu">
            <li class="nav-item">
                <a class="nav-link" href="/mon-profil">
                    <i class="material-icons">person</i>
                    <p>
                        <span class="d-lg-none d-md-block">Mon compte</span>
                    </p>
                </a>
            </li>
        </ul>
    </div>
    <ul class="nav">
        <li *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item" routerLinkActive="active">
            <a [routerLink]="[menuItem.path]" class="nav-link">
                <i class="material-icons">{{menuItem.icon}}</i>
                <p>{{menuItem.title}}</p>
            </a>
        </li>
    </ul>
</div>
