<app-spinner></app-spinner>
<div class="wrapper">
    <div class="sidebar" data-background-color="black" data-color="danger">
        <app-sidebar></app-sidebar>
    </div>
    <div class="main-panel">
        <app-navbar></app-navbar>
        <router-outlet></router-outlet>
        <app-footer></app-footer>
    </div>
</div>
