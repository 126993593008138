import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import {BehaviorSubject, map, Observable, shareReplay} from 'rxjs';
import {Auth} from "../models/auth.model";
import {environment} from "../environments/environment";
import {ApiResponse} from "../models/api_response.entity";
import {GenericCacheService} from "./generic.cache.service";
import {LoaderService} from "./loader.service";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private readonly apiUrl = environment.wsport > 0 ? environment.wsurl + ':' + environment.wsport : environment.wsurl;
    token: string = '';
    header;
    private isLoggedInSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isLoggedIn$: Observable<boolean> = this.isLoggedInSubject.asObservable();

    constructor(private loadingService: LoaderService, private jwtHelper: JwtHelperService, private http: HttpClient, private router: Router, private genericCacheService: GenericCacheService) {
        this.isLoggedInSubject.next(this.isLoggedIn());

        this.token = localStorage.getItem("admin_access_token");
        this.header = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    }

    /**
     * envoyer une requete sécurisée
     */
    getProtectedData() {
        const token = localStorage.getItem('admin_access_token');
        if (token && !this.jwtHelper.isTokenExpired(token)) {
            const headers = {'Authorization': `Bearer ${token}`};
            return this.http.get<any>(`${this.apiUrl}/`, {headers}).toPromise();
        } else {
            return Promise.reject('Token is invalid or expired');
        }
    }


    /**
     * Service d'Authentification
     * @param username
     * @param password
     */
    public login(username: string, password: string) {
        return this.http.post<any>(`${this.apiUrl}/auth/login/admin`, {username: username, password: password})
            .pipe(
                map(response => {
                    if (response && response.status == 'OK') {
                        localStorage.setItem('admin_access_token', response.data.access_token);
                        localStorage.setItem('admin_user', JSON.stringify(response.data.client));
                        localStorage.setItem('admin_role', response.data.role);
                        this.isLoggedInSubject.next(true);
                        return true;
                    } else {
                        this.isLoggedInSubject.next(false);
                        return response;
                    }
                })
            );
    }

    forgotten(login: string): Observable<ApiResponse> {
        const url = `${this.apiUrl}/auth/forgotten`;
        const result$ = this.http.post<ApiResponse>(url, {email: login, isAdmin: true});
        return result$;
    }

    find(id: number): Observable<ApiResponse> {
        let result$ = this.genericCacheService.getValue('find_auth_' + id);
        if (!result$) {
            const url = `${this.apiUrl}/auth/${id}`;
            result$ = this.http.get<ApiResponse>(url, {headers: this.header}).pipe(
                shareReplay(1)
            );
            this.genericCacheService.setValue(result$, 'find_auth_' + id);
        }
        return result$;
    }

    checkUniqueAuth(login: string, email: string, id?: number): Observable<Auth> {
        return this.http.post<Auth>(this.apiUrl + '/auth/check-unique-auth', {
            login: login,
            email: email, id: id
        }, {headers: this.header});
    }

    addAuth(auth: Auth): Observable<ApiResponse> {
        const $res = this.http.post<ApiResponse>(this.apiUrl + '/auth', auth, {headers: this.header});
        this.genericCacheService.clearCache();
        return $res;
    }

    updateAuth(id: number, auth: Auth): Observable<ApiResponse> {
        const url = `${this.apiUrl}/auth/${id}`;
        const $res = this.http.put<ApiResponse>(url, auth, {headers: this.header});
        this.genericCacheService.clearCache();
        return $res;
    }

    deleteAuth(id: number): Observable<ApiResponse> {
        const url = `${this.apiUrl}/auth/${id}`;
        const $res = this.http.delete<ApiResponse>(url, {headers: this.header});
        this.genericCacheService.clearCache();
        return $res;
    }

    /**
     * Get auth token depios local storage
     */
    public getToken(): any {
        let accessToken = localStorage.getItem('admin_access_token') ?? '';
        let user = localStorage.getItem('admin_user') ?? '';
        let role = localStorage.getItem('admin_role' ?? null);
        return {
            accessToken: accessToken,
            user: user,
            role: role
        }
    }

    public getUserInfo(): any {
        let token = this.getToken();
        if (token && token.accessToken) {
            try {
                let tokenContent = this.jwtHelper.decodeToken(token.accessToken);
                return tokenContent;
            } catch (error) {
                console.error(error);
                console.log('Erreur lors de la récupération des informations utilisateur. Redirection vers la page de connexion.');
                this.router.navigate(['/login']);
            }
        }
        return null;
    }

    public logout(): void {
        localStorage.removeItem('admin_access_token');
        localStorage.removeItem('user');
        localStorage.removeItem('role');
        this.isLoggedInSubject.next(false);
        // on revient sur la page d'authentification
        this.router.navigate(['/login']);
    }

    /**
     * Déconnexion de l'admin
     */
    adminLogout(): void {
        // on efface le local storage
        localStorage.removeItem('admin_access_token');
        localStorage.removeItem('user');
        localStorage.removeItem('role');
        this.isLoggedInSubject.next(false);
        // on revient sur la page d'authentification
        this.router.navigate(['/login']);
    }

    isLoggedIn(): boolean {
        let accessToken = localStorage.getItem('admin_access_token')
        if (accessToken !== null && accessToken !== '') {
            return true;
        }
        this.logout();
        return false;
    }

    public isTokenExpired(token: string): boolean {
        return this.jwtHelper.isTokenExpired(token);
    }

    redirectAfterTokenExpired(tokenExpired: boolean) {
        if (tokenExpired) {
            const user = this.getUserInfo();
            if (user.type === 1) {
                console.log('type admin: appel de la fonction adminLogout');
                this.adminLogout();
            } else {
                console.log('type autre que admin donc appel de la fonction logout');
                this.loadingService.setLoading(false);
                this.logout();
            }
        }
    }

    /**
     * Prend la liste des authentifications par clientId
     * @param id
     */
    getAuthsByClient(id: number): Observable<ApiResponse> {
        let result$ = this.genericCacheService.getValue('getAuthsByClient_' + id);
        if (!result$) {
            result$ = this.http.post<ApiResponse>(`${this.apiUrl}/auth/by-client`, {clientId: id}).pipe(
                shareReplay(1)
            );
            this.genericCacheService.setValue(result$, 'getAuthsByClient_' + id);
        }
        return result$;
    }

}
