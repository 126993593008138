import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from "../../services/auth.service";
import {AuthTypes} from "../../constants/AuthTypes.enum";

@Component({
    selector: 'app-authentication',
    templateUrl: './authentication.component.html',
    styleUrls: ['./authentication.component.scss']
})

export class AuthenticationComponent implements OnInit {
    loginForm: UntypedFormGroup;
    username!: string;
    password!: string;
    showPassword: boolean = false;
    loginError: boolean = false;
    loginErrorData: string = 'Email ou mot de passe invalide, veuillez réessayer.';

    constructor(private formBuilder: UntypedFormBuilder, private authService: AuthService, private router: Router) {
        // tester si on est déjà connecté
        this.checkIfSessionIsAlreadyOpen();
        // sinon on affiche le formulaire
        this.loginForm = this.formBuilder.group({
            emailClient: ['', [Validators.required]],
            password: ['', Validators.required]
        });
    }

    ngOnInit() {
    }

    /**
     * Tester si on est déjà authentifié (dans le local storage)
     */
    checkIfSessionIsAlreadyOpen() {
        // prendre les infos du local storage
        let tokenData = this.authService.getToken();
        if (tokenData && tokenData.accessToken !== '' && tokenData.role == 1) {
            if (tokenData.role == AuthTypes.SuperAdministrator) {
                this.router.navigate(['/dashboard']);
            }
        }
    }

    login() {
        if (this.loginForm.valid) {
            this.username = this.loginForm.get('emailClient')?.value;
            this.password = this.loginForm.get('password')?.value;
            this.authService.login(this.username, this.password).subscribe({
                next: (loggedIn) => {
                    if (loggedIn === true) {
                        let user = localStorage.getItem('admin_user') ?? '';
                        let role = localStorage.getItem('admin_role' ?? '');
                        if (role == '') {
                            console.log('Logged out')
                            this.router.navigate(['/logout']);
                        }
                        if (role === AuthTypes.SuperAdministrator.toString()) {
                            //console.log('SUPERADMIN');
                            this.router.navigate(['/dashboard']);
                        }
                    }
                    this.loginError = true;
                    this.loginErrorData = loggedIn.message;
                },
                error: (error) => {
                    console.log('error ', error);
                    this.loginError = true;
                },
            });
        }
    }
}
