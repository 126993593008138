import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {FooterComponent} from './admin/footer/footer.component';
import {NavbarComponent} from './admin/navbar/navbar.component';
import {SidebarComponent} from './admin/sidebar/sidebar.component';
import {FrontFooterComponent} from "./front/footer/front.footer.component";
import {FrontSidebarComponent} from "./front/sidebar/front.sidebar.component";
import {FrontNavbarComponent} from "./front/navbar/front.navbar.component";
import {GaugeComponent} from './gauge/gauge.component';
import {PieComponent} from './pie/pie.component';
import {TemperatureComponent} from './temperature/temperature.component';
import {LineGraphComponent} from './line-graph/line-graph.component';
import {TextComponent} from './text/text.component';
import {OnOffComponent} from './on-off/on-off.component';
import {MatCardModule} from "@angular/material/card";
import {GoogleChartsModule} from "angular-google-charts";
import {jqxGaugeModule} from "jqwidgets-ng/jqxgauge";
import {jqxChartModule} from "jqwidgets-ng/jqxchart";
import {MatIconModule} from "@angular/material/icon";
import {SpinnerComponent} from './spinner/spinner.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MatCardModule,
        GoogleChartsModule,
        jqxGaugeModule,
        jqxChartModule,
        MatIconModule,
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        FrontFooterComponent,
        FrontSidebarComponent,
        FrontNavbarComponent,
        GaugeComponent,
        PieComponent,
        TemperatureComponent,
        LineGraphComponent,
        TextComponent,
        OnOffComponent,
        SpinnerComponent
    ],
    exports: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        FrontFooterComponent,
        FrontSidebarComponent,
        FrontNavbarComponent,
        GaugeComponent,
        PieComponent,
        TemperatureComponent,
        LineGraphComponent,
        TextComponent,
        OnOffComponent,
        SpinnerComponent
    ]
})
export class ComponentsModule {
}
